import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignmentDetailService {

  constructor(private httpClient: HttpClientService) { }

  search(params: any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/Search`;
    return this.httpClient.post(URL, params, false);
  }

  create(params: any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail`;
    return this.httpClient.post(URL, params, false);
  }

  update(params: any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail`;
    return this.httpClient.put(URL, params, false);
  }

  getById(id:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/${id}`;
    return this.httpClient.get(URL, false);
  }

  delete(params:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/Delete`;
    return this.httpClient.post(URL, params, false);
  }

  updateBookingAdhocposition(params:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/UpdateBookingAdhocposition`;
    return this.httpClient.put(URL, params, false);
  }

  getBookingAdhocposition(mstAdHocPositionId:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/GetBookingAdHocPositionDetail/${mstAdHocPositionId}`;
    return this.httpClient.get(URL, false);
  }

  //nationTab
  createFather(params: any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentFamilyFather`;
    return this.httpClient.post(URL, params, false);
  }

  updateFather(params: any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentFamilyFather`;
    return this.httpClient.put(URL, params, false);
  }

  getByIdFather(id:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentFamilyFather/${id}`;
    return this.httpClient.get(URL, false);
  }

  //Evidence tab
  getAssignmentEvidence(id:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentEvidence/${id}`;
    return this.httpClient.get(URL, false);
  }

  updateAssignmentEvidence(params:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentEvidence`;
    return this.httpClient.put(URL, params ,false);
  }

  //Qualification tab
  getAssignmentQualification(id:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentQualification/${id}`;
    return this.httpClient.get(URL, false);
  }

  updateAssignmentQualification(params:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/AssignmentQualification`;
    return this.httpClient.put(URL, params ,false);
  }

  updateMilitaryRank(params:any){
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/UpdateMilitaryRank`;
    return this.httpClient.put(URL, params ,false);
  }
  //end Qualification tab


  getSendAssignmentToOrder(params:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/sendAssignmentToOrder`;
    return this.httpClient.post(URL, params , false);
  }

  searchAssignmentFollowStatus(params:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/SearchAssignmentFollowStatus`;
    return this.httpClient.post(URL, params , false);
  }

  searchPersonalList(params:any) {
    const URL: string = environment.apiAsmUrl + `AssignmentDetail/SearchPersonalList`;
    return this.httpClient.post(URL, params , false);
  }
}
