<div class="card shadow-1 my-3">
    <form [formGroup]="dataForm">
        <p-panel header="ค้นหารายชื่อ" [toggleable]="true" [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'" styleClass="mt-2 mb-3">
            <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-3">
                    <label>ค้นหา</label>
                    <input pInputText placeholder="เลขประจำตัวประชาชน, ชื่อ-นามสกุล" formControlName="keyword"/>
                </div>
                <ng-container *ngIf="from == 'retire' || from == 'transfer-type'">
                    <div class="field col-12 md:col-3">
                        <label>ปี</label>
                        <p-dropdown [options]="yearList" optionValue="value" optionLabel="text" filterBy="text" 
                            [filter]="true" [showClear]="true" placeholder="เลือก" formControlName="year" appendTo="body">
                        </p-dropdown>
                    </div>
                </ng-container>
                <ng-container *ngIf="applicationId == 7">
                    <div class="field col-12 md:col-3">
                        <label>หน่วยงาน</label>
                        <p-dropdown [options]="orgUnitId1List" optionLabel="text" optionValue="value" [filter]="true" [autoDisplayFirst]="false" formControlName="mstOrgUnitId"
                        filterBy="name" [showClear]="true" placeholder="เลือก" (onChange)="onChangeOrgUnitId1($event)" appendTo="body">
                        </p-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label>ระดับกรม</label>
                        <p-dropdown [options]="orgUnitId2List" optionLabel="text" optionValue="value" [filter]="true" [autoDisplayFirst]="false" formControlName="mstOrgUnitParentId"
                        filterBy="name" [showClear]="true" placeholder="เลือก" appendTo="body" >
                        </p-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label>ยศ</label>
                        <p-dropdown [options]="mstPositionRankList" optionLabel="text" optionValue="value" [filter]="true" filterBy="text" appendTo="body"
                            [showClear]="true" [autoDisplayFirst]="false" placeholder="เลือก" class="w-full" formControlName="mstPositionRankId" >
                        </p-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label>ประเภทนายทหาร</label>
                        <p-dropdown [options]="mstPersonnelGroupList" optionLabel="text" optionValue="value" [filter]="true" filterBy="text" appendTo="body"
                            [showClear]="true" [autoDisplayFirst]="false" placeholder="เลือก" class="w-full" formControlName="mstPersonnelGroupId" >
                        </p-dropdown>
                    </div>
                </ng-container>
                <div class="field col-12 md:col flex justify-content-end align-items-end max-h-full gap-3">
                    <p-button label="ค้นหา" icon="fa-light fa-magnifying-glass" size="small" iconPos="left" styleClass="bg-purple-900 w-10rem" (onClick)="searchAction()"></p-button>
                    <p-button label="ล้างข้อมูล" icon="fa-light fa-x" size="small" iconPos="left" [outlined]="true" severity="danger" styleClass="w-10rem" (onClick)="cancel()"></p-button>
                </div>
            </div>
        </p-panel>
    </form>

    <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [scrollable]="true" scrollHeight="400px"
        [showCurrentPageReport]="true" [paginator]="true" [lazy]="true" (onLazyLoad)="searchAction($event)" 
        [lazyLoadOnInit]="false" [showCurrentPageReport]="true" [first]="first" [sortOrder]="sortOrder" paginatorDropdownAppendTo="body"
        [sortField]="sortField" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" styleClass="p-datatable-sm"
        [totalRecords]="totalRecords" currentPageReportTemplate="แสดง {first} ถึง {last} จาก {totalRecords} รายการ">
        <ng-template pTemplate="caption">
            <h5 class="text-purple-900 mb-0">รายการ</h5>
        </ng-template>

        <ng-template pTemplate="header">
            <tr class="white-space-nowrap">
                <th pSortableColumn="citizenId">
                    <span>เลขประจำตัวประชาชน</span>
                    <p-sortIcon field="citizenId"></p-sortIcon>
                </th>
                <th pSortableColumn="militaryId">
                    <span>เลขประจำตัวทหาร</span>
                    <p-sortIcon field="militaryId"></p-sortIcon>
                </th>
                <th pSortableColumn="fullName">
                    <span>ยศ-ชื่อ-นามสกุล</span>
                    <p-sortIcon field="fullName"></p-sortIcon>
                </th>
                <th pSortableColumn="positionShortName">
                    <span>ตำเเหน่ง</span>
                    <p-sortIcon field="positionShortName"></p-sortIcon>
                </th>
                <th pSortableColumn="overAllWork">
                    <span>จำนวนปี อายุราชการรวม</span>
                    <p-sortIcon field="overAllWork"></p-sortIcon>
                </th>
                <th pSortableColumn="expectedRetirementDate">
                    <span>วันครบเกษียณอายุ</span>
                    <p-sortIcon field="expectedRetirementDate"></p-sortIcon>
                </th>
                <th pSortableColumn="birthDate">
                    <span>วันเดือนปีเกิด</span>
                    <p-sortIcon field="birthDate"></p-sortIcon>
                </th>
                <th pSortableColumn="birthDateText">
                    <span>อายุ</span>
                    <p-sortIcon field="birthDateText"></p-sortIcon>
                </th>
                <th pSortableColumn="retirementMilitary">
                    <span>ประเภททหาร</span>
                    <p-sortIcon field="retirementMilitary"></p-sortIcon>
                </th>
                <th pSortableColumn="retirementSalaryType">
                    <span>ประเภทการรับเงิน</span>
                    <p-sortIcon field="retirementSalaryType"></p-sortIcon>
                </th>
                <th pSortableColumn="orgUnitShortName">
                    <span>สังกัด</span>
                    <p-sortIcon field="orgUnitShortName"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-data>
            <tr class="white-space-nowrap cursor-pointer hover:bg-gray-400" (click)="selectPerson(data)" >
                <td>{{data.citizenId}}</td>
                <td>{{data.militaryId}}</td>
                <td>{{data.fullName}}</td>
                <td>{{data.positionShortName}}</td>
                <td>{{data.overAllWork}}</td>
                <td>{{data.expectedRetirementDate ? (data.expectedRetirementDate | formatShortDateThai) : null}}</td>
                <td>{{data.birthDate ? (data.birthDate | formatShortDateThai) : null}}</td>
                <td>{{data.birthDateText}}</td>
                <td>{{data.retirementMilitary}}</td>
                <td>{{data.retirementSalaryType}}</td>
                <td>{{data.orgUnitShortName}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <ng-container *ngIf="dataSource.length <= 0">
                <tr>
                    <td colspan="11" class="text-left bg-white">
                        ไม่มีข้อมูล
                    </td>
                </tr>
            </ng-container>
        </ng-template>
    </p-table>
</div>

<div class="flex justify-content-center gap-3 my-3">
    <p-button label="ปิด" icon="fa-light fa-x" iconPos="left" size="small" [outlined]="true" severity="danger" styleClass="w-10rem" (onClick)="close(null)"></p-button>
</div>