import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrackingUnitTreeService {

  constructor(private httpClientService: HttpClientService ) { }

  getTrackingUnitTree(param: any) {
    const url = environment.apiTransUrl + 'TrackingUnitTree/Tree';
    return this.httpClientService.post(url,param,  false);
  }

}
